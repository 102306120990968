<template>
  <v-container class=" mx-auto mt-5  mb-16">
    <p class="headline font-weight-medium pediidos--text mb-5 ">{{$t('onboarding.title')}}</p>
    <p class=" pediidos--text mb-5 ">{{$t('onboarding.message')}} </p>
    <v-sheet >
      <v-row >
        <v-col class="pb-0 "  cols="12" lg="4" >
          <v-card class="mx-auto mt-5" elevation="4">
            <v-card-title style="word-break: normal;" class="headline text-center align-center justify-center py-10">{{$t('profile_picture')}} </v-card-title>
              <v-form class=" pa-5 text-center" ref="form"  lazy-validation>
                <v-avatar size="27.5vh"  class="mb-5" >
                  <v-img v-if=" picture == '' " src="https://firebasestorage.googleapis.com/v0/b/servi-app-ec.appspot.com/o/misitio%2Fno-image.png?alt=media"></v-img>
                  <v-img v-else :src="picture"></v-img>
                </v-avatar>
                <v-file-input
                  @change="previewAvatar($event)"
                  accept="image/png, image/jpeg"
                  :label="this.$t('avatar')"
                  counter
                  show-size
                  prepend-icon="mdi-camera"
                  :rules="picture_rules"
                  outlined
                  color="pediidos"
                ></v-file-input>
                <v-btn class="ma-2" outlined rounded color="pediidos" @click="uploadImage()" >{{$t('save')}} </v-btn>
              </v-form>
          </v-card>
        </v-col>
        <v-col class="pb-0 "  cols="12" lg="8" >
          <v-card class=" mx-auto mt-5"  elevation="4">
            <v-card-title style="word-break: normal;" class="headline text-center align-center justify-center  py-10">{{$t('onboarding.qn_shop_location')}}</v-card-title>
              <v-form class="pa-5 text-center" ref="addressForm" lazy-validation>
                <v-row>
                  <v-col class="py-0 " cols="12" md="6" >
                    <v-autocomplete
                      @change="onChangeCity()"
                      v-model="address.city"
                      :items="cities"
                      :rules="rules.city"
                      :label="this.$t('city')"
                      :placeholder="this.$t('city')"
                      color="pediidos"
                      item-color="pediidos"
                      prepend-icon="mdi-city-variant-outline"
                      auto-select-first
                      chips
                      small-chips
                      outlined
                    ></v-autocomplete>
                    <v-card-text class=" py-1 caption text-right pediidos--text ">{{$t('onboarding.msg_shop_address')}}</v-card-text>
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('address')"
                      :placeholder="this.$t('onboarding.address_placeholder')"
                      v-model.trim="address.address"
                      :rules="rules.address"
                      prepend-icon="mdi-map-marker"
                      append-icon="mdi-magnify"
                      autocomplete="off"
                      outlined
                      @click:append="validedAddress()"
                      @keyup.enter="validedAddress()"
                      :disabled="( address.city == '' )"
                    ></v-text-field>
                    <v-text-field
                      v-model="address.detail"
                      :rules="rules.detail"
                      color="pediidos"
                      :label="this.$t('detail')"
                      :placeholder="this.$t('onboarding.detail_placeholder')"
                      prepend-icon="mdi-comment-alert-outline"
                      autocomplete="off"
                      outlined
                      :disabled="( address.city == '' )"
                    ></v-text-field>
                    <v-text-field
                    v-model="address.alias"
                    :rules="rules.alias"
                    color="pediidos"
                    :label="this.$t('alias')"
                    :placeholder="this.$t('onboarding.alias_placeholder')"
                    prepend-icon="mdi-tag"
                    autocomplete="off"
                    outlined
                    :disabled="( address.city == '' )"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0 " cols="12" md="6" >
                    <v-sheet class="mb-5">
                      <v-card-text class=" pt-0 caption text-right pediidos--text ">{{$t('onboarding.msg_shop_location')}}</v-card-text>
                      <div id="map_onboarding" style="width: 100%; height: 44vh"></div>
                    </v-sheet>
                  </v-col>
                </v-row>
                  <v-btn class="ma-2" outlined rounded color="pediidos" @click="updateAddress()" >{{$t('save')}}</v-btn>
              </v-form>
          </v-card>
        </v-col>
      </v-row>
      <div class=" mt-10 mb-5 text-center align-center justify-center">
        <p class=" pediidos--text mb-5 ">{{$t('onboarding.back_home')}}:</p>
        <v-btn x-large rounded color="error" @click="logout()" >
          {{$t('_logout')}}
          <v-icon class="ml-2">mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-sheet>
  </v-container>
</template>

<script type="text/javascript">
  import auth from '@/firebase/auth.js';
  import FileFirebase from '@/firebase/files.js';
  import Floppy from '@/helpers/IndexedDB.js';
  import Api from '@/helpers/api.js';
  import * as constants from '@/constants';
  import { mapMutations, mapState } from 'vuex';
  export default {
    name: 'onboarding',
    data() {
      return {
        success_avatar: false,
        success_address: false,
        picture: '',
        picture_temp: null,
        picture_rules: [v => !v || v.size < 2000000 || this.$t('error.size_avatar'),],
        cities: [],
        address: {
          address: '',
          city: '',
          detail: '',
          alias: '',
          lat: undefined,
          lng: undefined,
        },
        rules: {
          city: [v => !!v || this.$t('error.required_city'), v => (v && v != '' ) || this.$t('error.empty_city'),],
          address: [v => !!v || this.$t('error.required_address'), v => (v && v.length <= 100 && v.length >= 4 ) || this.$t('error.length_address'),],
          detail: [v => !!v || this.$t('error.required_detail'), v => (v && v.length <= 50 && v.length >= 4 ) || this.$t('error.length_detail'),],
          alias: [v => !!v || this.$t('error.required_alias'), v => (v && v.length <= 50 && v.length >= 4 ) || this.$t('error.length_alias'),],
        },
      }
    },
    watch: {
      success_avatar(value) {
        if(value && this.success_address){
          setTimeout(()=>{ this.goHome(); }, 2000);
        }
      },
      success_address(value) {
        if(value && this.success_avatar){
          setTimeout(()=>{ this.goHome(); }, 2000);
        }
      },
    },
    mounted(){
      this.getCyties();
      setTimeout(()=>{this.getCurrentPosition();}, 200);
    },
    methods: {
      ...mapMutations(['APP_LOADER','USER_LOGGED','SET_USER']),
      async uploadImage(){
        if(this.picture_temp == null){
          return this.myNotify('error',this.$t('_error'),this.$t('error.required_avatar'));
        }
        let imageFile = this.picture_temp;
        return new Promise(async(resolve,reject)=>{
          try{
            var extension = imageFile['type'].split('/')[1];
            var name = 'profile-'+this.user.user_id+'.'+extension;
            var company = constants.COMPANY_NAME.replace(" ", "-");
            const url = await FileFirebase.uploadFile(imageFile,'misitio/pictures_profiles/',(company+'-'+name));
            let data = {"user_id": this.user.user_id,"image": url}
            const result = await Api.put('users/update/profile/',data);
            this.picture_temp = null;
            this.user.picture = url;
            await Floppy.update('users',{picture:url}, this.user.firebase_uid);
            this.myNotify('success',this.$t('_success'),this.$t('onboarding.msg_update_profile_picture'));
            this.success_avatar = true;
            resolve(true)
          }catch(error) {
            console.log('Error ',error);
            reject(false)
          }
        })
      },
      previewAvatar(e) {
        if(e == null){
          this.picture = this.user.picture;
          this.picture_temp = null;
        }else{
          this.picture = URL.createObjectURL(e);
          this.picture_temp = e;
        }
      },
      getCurrentPosition() {
        const options = {enableHighAccuracy:true,maximumAge:0,timeout:5000};
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.getBrowserLocation, this.errorBrowserLocation, options);
        } else {
            this.errorBrowserLocation()
        }
      },
      errorBrowserLocation() {
        this.getBrowserLocation(null);
        this.myNotify('warn',this.$t('_warning'),this.$t('error.current_position'));
      },
      getBrowserLocation(position) {
        if(position !== null){
          Anuket.run('#map_onboarding',{'zoom': 12,'latlng': {'lat': position.coords.latitude,'lng': position.coords.longitude} });
          this.addMarker(position.coords.latitude,position.coords.longitude);
          this.inversegeocoder(position.coords.latitude,position.coords.longitude);
        }else{
          Anuket.run('#map_onboarding',{'zoom': 10,'latlng': constants.COORDINATES_DEFAULT });
        }
      },
      async getCyties() {
        this.APP_LOADER(true);
        Api.post('shopkeepers/cities/').then((result)=> {
          let myCities = [];
          result.data.forEach(function(e) {
            myCities.push(e.name);
          });
          this.cities = myCities;
        }).catch((error)=> {
          this.myNotify('error',this.$t('_error'),this.$t('load_cities'));
          console.log('This is error ',error);
        })
        setTimeout(()=>{ this.APP_LOADER(false); }, 500);
      },
      updateAddress() {
        if( !this.$refs.addressForm.validate() ){
          return this.myNotify('error',this.$t('_error'),this.$t('error.required_address_files'));
        }
        if (this.address.lat == undefined || this.address.lng == undefined){
          return this.myNotify('error',this.$t('_error'),this.$t('error.empty_location'));
        }
        let data = {
            user_id: this.user.user_id,
            alias: this.address.alias,
            address: this.address.address,
            detail: this.address.detail,
            lat: this.address.lat.toString(),
            lon: this.address.lng.toString(),
            city: this.address.city
          }
        Api.put('users/add/address/', data).then((result)=> {
          if(result.status < 300) {
            this.user.address_alias = this.address.alias;
            this.user.address_detail = this.address.detail;
            this.user.address = this.address.address;
            this.user.lat = this.address.lat.toString();
            this.user.lon = this.address.lng.toString();
            this.user.city = this.address.city;
            Floppy.update('users', this.user, this.user.firebase_uid);
            this.myNotify('success',this.$t('_success'),this.$t('onboarding.msg_update_address'));
            this.success_address = true;
          }else{
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
            console.log('This is error ',result.detail);
          }

        }).catch((error)=> {
          console.log('This is error ',error);
        })
      },
      addMarker(lat,lng){
        Anuket.clearMap();
        var options = {
          draggable: true,
          onDragend: (e, coords) =>{
            this.address.lat = coords.lat;
            this.address.lng = coords.lng;
            this.inversegeocoder(coords.lat,coords.lng);
          },
        };
        Anuket.addMarker({lat:lat, lng: lng}, options);
        Anuket.centerMap(lat, lng);
        Anuket.setZoom(18);
      },
      validedAddress(){
        if(this.address.address.length > 3 ) {
          this.geocoder(this.address.address);
        }
      },
      geocoder(address) {
        this.address.lat = constants.COORDINATES_DEFAULT.lat;
        this.address.lng = constants.COORDINATES_DEFAULT.lng;
        var options = {
          country: constants.COUNTRY,
        }
        var full_address = address+','+this.address.city+','+constants.COUNTRY;
        Anuket.geocoder(full_address, (coords, results, status) => {
          console.log('geocoder',results);
          if (status === 'OK'){
            this.addMarker(coords.lat,coords.lng);
            this.address.lat = coords.lat;
            this.address.lng = coords.lng;
            this.address.address = results[0].formatted_address;
          }
        }, options);
      },
      inversegeocoder(lat,lng){
        Anuket.inverseGeocoder({lat: lat, lng: lng}, (address, results, status) => {
          this.address.address = address
        });
      },
      onChangeCity(){
        console.log("onChangeCity");
        this.address.address = '';
        this.address.lat = undefined;
        this.address.lng = undefined;
      },
      goHome(){
        setTimeout(()=>{ this.APP_LOADER(false); }, 500);
        this.USER_LOGGED(true);
        this.$router.push('/home');
      },
      async logout() {
          Floppy.truncate('users');
          localStorage.clear('key');
          localStorage.clear('shoppingCart');
          await auth.logout();
          this.USER_LOGGED(false);
          if(this.$route.path !== '/'){
            this.$router.push({name: 'Login'});
          }
      },
    },
    computed: {
      ...mapState(['appLoader','userlogged','user']),
    },
  }
</script>

<style type="text/css" scoped>
</style>