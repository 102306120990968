import { storage } from './InitFirebase.js';
import moment from 'moment';

export default {
    uploadFile(file, folder, name) {
        return new Promise((resolve, reject)=> {
            if(name !== undefined) {
                var nameFile = folder+'/'+name;
            }else {
                var nameFile = folder+'/'+moment().format('YYYYMMDDHHmmss')+'.xlsx';
            }

            var storageRef = storage.ref();
            const metaData = { cacheControl: 'public' };
            var uploadTask = storageRef.child(nameFile).put(file, metaData);

            uploadTask.on('state_changed',function(snapshot){
                 var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                 console.log('Upload is '+progress.toFixed(2)+' % done');
            }, function(error){
                 console.log(error);
                 reject(error);
            },function(){
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
                     // console.log(downloadURL);
                     resolve(downloadURL);
                });
            });
        })
    },
    uploadFiles(files, folder, nameBase) {
        var storageRef = storage.ref();
        const metaData = { cacheControl: 'public' };
        console.log('Este es el nameBase ',nameBase);

        var promise= [];

        for(var i in files) {
            if(typeof(files[i]) == 'object'){
                var extension = files[i].type.split('/')[1];
                var name = folder+nameBase+'-'+i+'.'+extension;
                const task = storageRef.child(name).put(files[i], metaData).then(function(snap){
                    return snap.ref.getDownloadURL();
                })
                promise.push(task);
            }
        }
        return Promise.all(promise);
    },
    deleteFile(urlFile) {
        var storageRef = storage.ref();
        var desertRef = storageRef.child(urlFile);
        return desertRef.delete();

        /*desertRef.delete().then(function() {
          console.log('El archivo se elimino satisfactoriamente');
        }).catch(function(error) {
          console.log('Error al eliminar el archivo');
        });*/
    }
}